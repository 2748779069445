$(document).ready(function(){

    $('.media-list .media-list__item a').click(function(event){
        event.preventDefault();

        var $smallImagePreview = $('.media__preview span.small-image__preview img');
        var $largeImagePreview = $('.media__preview span.large-image__preview img');
        var $originalImagePreview = $('.media__preview span.original-image__preview img');

        var smallImage1 = $(this).find('span.small-image img').attr('src');
        var largeImage1 = $(this).find('span.large-image img').attr('src');
        var originalImage1 = $(this).find('span.original-image img').attr('src');

        var smallImage2 = $smallImagePreview.attr('src');
        var largeImage2 = $largeImagePreview.attr('src');
        var originalImage2 = $originalImagePreview.attr('src');

        $smallImagePreview.attr('src', smallImage1);
        $largeImagePreview.attr('src', largeImage1);
        $originalImagePreview.attr('src', originalImage1);


        $(this).find('span.small-image img').attr('src', smallImage2);
        $(this).find('span.large-image img').attr('src', largeImage2);
        $(this).find('span.original-image img').attr('src', originalImage2);

        $('.zoomImg').attr('src', originalImage1);

    });

});
