$(document).ready(function() {

    //DISABLE
    $("html").on("contextmenu",function(e){
       return false;
    });
    
    // Navigation active
    var activePage = $('main div').first().attr('id');
    console.log(activePage);
    switch (activePage) {
        case 'collection':
            $('#collection-menu').css({
                'background-color': '#d92025',
                'color': '#fff'
            });
            break;
        case 'outlets':
            $('#outlets-menu').css({
                'background-color': '#d92025',
                'color': '#fff'
            });
            break;
        case 'about':
            $('#about-menu').css({
                'background-color': '#d92025',
                'color': '#fff'
            });
            break;
        case 'news':
            $('#news-menu').css({
                'background-color': '#d92025',
                'color': '#fff'
            });
            break;
        default:
            break;
    }

    // Scroll top
    $('#scrollTop').click(function(event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    //NAVIGATION
    $('.dropdown-2 .dropdown-toggle').click(function(event) {
        event.preventDefault();
        $('.dropdown-1 .dropdown-toggle').parent().removeClass('dropdown-toggle__active');
        $('.dropdown-1 .dropdown-menu').removeClass('dropdown-menu__active');
        $('.dropdown-2 .dropdown-toggle').toggleClass('dropdown-toggle__active');
        $('.dropdown-2 .dropdown-slider').toggleClass('dropdown-slider__active');
    });

    $('.dropdown-1 .dropdown-toggle').click(function(event) {

        event.preventDefault();
        $('.dropdown-2 .dropdown-toggle').removeClass('dropdown-toggle__active');
        $('.dropdown-2 .dropdown-slider').removeClass('dropdown-slider__active');

        if ($(this).hasClass('dropdown-toggle__active')) {
            $('.dropdown-1 .dropdown-toggle').removeClass('dropdown-toggle__active');
            $('.dropdown-1 .dropdown-toggle').next().removeClass('dropdown-menu__active');
        } else {
            $('.dropdown-1 .dropdown-toggle').removeClass('dropdown-toggle__active');
            $('.dropdown-1 .dropdown-toggle').next().removeClass('dropdown-menu__active');
            $(this).addClass('dropdown-toggle__active');
            $(this).next().addClass('dropdown-menu__active');
        }
    });

    //SOCIAL MEDIA
    $('.header-social #facebook, .header-social #instagram, .header-social #pinterest').mouseenter(function() {
        $(this).find('img#default').css('display', 'none');
        $(this).find('img#hover').css('display', 'block');
    });

    $('.header-social #facebook, .header-social #instagram, .header-social #pinterest').mouseleave(function() {
        $(this).find('img#default').css('display', 'block');
        $(this).find('img#hover').css('display', 'none');
    });

    //FOOTER SOCIAL MEDIA
    $('.social-row #facebook, .social-row #instagram, .social-row #pinterest').mouseenter(function() {
        $(this).find('img#default').css('display', 'none');
        $(this).find('img#hover').css('display', 'block');
    });

    $('.social-row #facebook, .social-row #instagram, .social-row #pinterest').mouseleave(function() {
        $(this).find('img#default').css('display', 'block');
        $(this).find('img#hover').css('display', 'none');
    });

    // Magnify product images
    $zoomSource = $('div.media__preview #zoomSource').attr('src');
    $('div.media__preview').zoom({ url: $zoomSource, magnify: 0.67 });

    //Collection dropdown menu
    var sliderButtonFlag = true;
    var flag = 1;
    var $item = $('.dropdown-slider__container ul.dropdown-menu li');
    var count = $item.length;
    var itemWidth = $item.width();

    var DropdownSliderModule = (function() {

        var speed = 500;

        var _forward = function(count, width, $block) {

            var bkVal = '-'+width*(count-3)+'px';

            if (bkVal == $block.css('margin-left')) {
                $block.animate({ 'margin-left': '0px' }, speed, function() {
                    sliderButtonFlag = true;
                });
            } else {
                $block.animate({ 'margin-left': '-=' + width }, speed, function() {
                    sliderButtonFlag = true;
                });
            }

        };

        var _backward = function(count, width, $block) {

            var bkVal = width*(count-3)+'px';

            if (0 + 'px' == $block.css('margin-left')) {
                $block.animate({ 'margin-left': '-' + bkVal }, speed, function() {
                    sliderButtonFlag = true;
                });
            } else {
                $block.animate({ 'margin-left': '+=' + width }, speed, function() {
                    sliderButtonFlag = true;
                });
            }

        };

        var slideReceiver = function(count, width, button) {

            var $block = $('.dropdown-slider__container ul.dropdown-menu');

            switch (button) {
                case 'backward':
                    _backward(count, width, $block);
                    break;
                case 'forward':
                    _forward(count, width, $block);
                    break;
                default:
                    console.log('unknown value!');
            }

        };

        return {
            receiver: slideReceiver
        };

    })();



    switch (count) {
        case 3:
            $('.dropdown-slider').find('.forward, .backward').css('display', 'none');
            $('.dropdown-slider').css('width', '452px');
            break;
        case 2:
            $('.dropdown-slider').find('.forward, .backward').css('display', 'none');
            $('.dropdown-slider').css('width', '321px');
            break;
        case 1:
            $('.dropdown-slider').find('.forward, .backward').css('display', 'none');
            $('.dropdown-slider').css('width', '189px');
            break;
        default:
            $('.dropdown-slider').children('a').bind('click', function(event) {
                event.preventDefault();
                if (sliderButtonFlag === true) {
                    sliderButtonFlag = false;
                    var itemWidth = $item.width();
                    var buttonClicked = $(this).attr('class');
                    DropdownSliderModule.receiver(count, itemWidth, buttonClicked);
                }
            });
            break;
    }

    $('.dropdown-slider__container ul.dropdown-menu').css('margin-left', '0px');

    $('.share-list li a#shareBtn').click(function(event){
        event.preventDefault();
    });

});
