var directionHeight = $('.kingine-direction').height();
directionHeight = 0-(directionHeight/2);

$('.kingine-direction').css({
    'bottom' : '50%',
    'margin-bottom' : directionHeight
});

// var duplicateThis = $('.kingine-shop-slider .kingine-list').html();
//
// for(var n = 0; n < 9; n++) {
//     $('.kingine-shop-slider__block .kingine-list').append(duplicateThis);
// }

$(function(){

    //some variables
    var speed = 300;
    var pauseDiscover = 5000;
    var intervalDiscover;
    var pausePopular = 5000;
    var intervalPopular;
    var flag = 0;
    var limit = 5;
    var quantity = 10;
    var sliderButtonFlag = true;

    // Getting the layout of slider
    var perWidth = $('.kingine-shop-slider .kingine-list__item').width();
    var wrapWidth = limit * perWidth;
    var checkLimit = quantity * perWidth;
    $('.kingine-shop-slider .kingine-shop-slider__block').css('width', wrapWidth);
    $('.kingine-shop-slider .kingine-list').css('width', checkLimit);
    var reverseValue = (quantity-limit)*perWidth;
    var moveDiscover = 'forward';
    var movePopular = 'backward';

    // animation
    intervalDiscover = setInterval(function () {

        if($('.kingine-shop-slider .kingine-list#discover').css('margin-left') == '-'+reverseValue+'px') moveDiscover = 'backward';
        if($('.kingine-shop-slider .kingine-list#discover').css('margin-left') == '0px') moveDiscover = 'forward';

        switch (moveDiscover) {
            case 'forward':
                $('.kingine-shop-slider .kingine-list#discover').animate({
                    'margin-left' : '-='+perWidth
                }, speed, function() {
                    //sliderButtonFlag = true;
                });
                break;
            case 'backward':
                $('.kingine-shop-slider .kingine-list#discover').animate({
                    'margin-left' : '+='+perWidth
                }, speed, function() {
                    //sliderButtonFlag = true;
                });
                break;
            default:
                console.log('Oops!');
                break;
        }
    }, pauseDiscover);

    $('.kingine-shop-slider .kingine-list#popular').css('margin-left', '-1140px');

    // animation
    intervalPopular = setInterval(function () {

        if($('.kingine-shop-slider .kingine-list#popular').css('margin-left') == '-'+reverseValue+'px') movePopular = 'backward';
        if($('.kingine-shop-slider .kingine-list#popular').css('margin-left') == '0px') movePopular = 'forward';

        switch (movePopular) {
            case 'forward':
                $('.kingine-shop-slider .kingine-list#popular').animate({
                    'margin-left' : '-='+perWidth
                }, speed, function() {
                    //sliderButtonFlag = true;
                });
                break;
            case 'backward':
                $('.kingine-shop-slider .kingine-list#popular').animate({
                    'margin-left' : '+='+perWidth
                }, speed, function() {
                    //sliderButtonFlag = true;
                });
                break;
            default:
                console.log('Oops!');
                break;
        }
    }, pausePopular);

    $('.kingine-direction__previous').click(function(event){
        event.preventDefault();

        if(sliderButtonFlag == true) {
            sliderButtonFlag = false;

            if ($(this).parent().parent().find('.kingine-shop-slider__block').children().css('margin-left') == '0px') {
                $(this).parent().parent().find('.kingine-shop-slider__block').children()
                .animate({
                    'margin-left' : '-'+wrapWidth+'px'
                }, speed, function() {
                    sliderButtonFlag = true;
                });
            } else {
                $(this).parent().parent().find('.kingine-shop-slider__block').children()
                .animate({
                    'margin-left' : '+='+perWidth
                }, speed, function() {
                    sliderButtonFlag = true;
                });
            }
        }

    });

    $('.kingine-direction__next').click(function(event){
        event.preventDefault();

        if(sliderButtonFlag == true) {
            sliderButtonFlag = false;

            if ($(this).parent().parent().find('.kingine-shop-slider__block').children().css('margin-left') == '-'+reverseValue+'px') {
                $(this).parent().parent().find('.kingine-shop-slider__block').children()
                .animate({
                    'margin-left' : '0px'
                }, speed, function() {
                    sliderButtonFlag = true;
                });
            } else {
                $(this).parent().parent().find('.kingine-shop-slider__block').children()
                .animate({
                    'margin-left' : '-='+perWidth
                }, speed, function() {
                    sliderButtonFlag = true;
                });
            }
        }
    });

});
